<template>
  <div
      class="time-line"
      infinite-scroll-immediate
      v-infinite-scroll="load"
  >
    <el-timeline>
      <el-timeline-item
          :key="activity.id"
          :timestamp="activity.created_at"
          placement="top"
          type="primary"
          v-for="activity in data"
      >
        <div style="color: #3A71A8; font-size: 0.9em">
          {{ activity.causer_name }}
        </div>
        <template v-if="activity.description === 'created'">
          <b>{{ $t(`system.${getSubjectType(activity).toLowerCase()}`) }}</b> - {{ $t('system.created') }}
        </template>
        <template v-else-if="activity.description === 'updated'">
          <template v-if="activity.properties.length">
            <div
                :key="idx"
                v-for="(actions, idx) in activity.properties"
            >
              <div
                  :key="target"
                  v-for="(action, target) in actions"
              >
                <b>{{ $t(`system.${target}`) }}: </b>
                <template v-if="action.oldValue && action.oldValue.length">
                  {{ $t('system.changed') }}
                  <template v-if="Array.isArray(action.oldValue)">
                <span
                    :key="idx"
                    v-for="(item, idx) in action.oldValue"
                >
                  <u>{{ item }}</u>{{ action.oldValue.length > idx + 1 ? ', ' : '' }}
                </span>
                  </template>
                  <template v-else>
                    <u>{{ action.oldValue }}</u>
                  </template>
                  {{ $t('system.changed_to') }}
                </template>
                <template v-else>
                  {{ $t('system.added') }}
                </template>
                <template v-if="Array.isArray(action.newValue)">
              <span
                  :key="getRandomInt() + idx"
                  v-for="(item, idx) in action.newValue"
              >
                <u>{{ item }}</u>{{ action.newValue.length > idx + 1 ? ', ' : '' }}
              </span>
                </template>
                <template v-else>
                  <u>{{ action.newValue }}</u>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <b>{{ $t(`system.${getSubjectType(activity).toLowerCase()}`) }}</b> - {{ $t('system.updated') }}
          </template>
        </template>
        <template v-else-if="activity.description === 'deleted'">
          <b>{{ $t(`system.${getSubjectType(activity).toLowerCase()}`) }}</b> - {{ $t('system.deleted') }}
        </template>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  name: 'WorkOrderTimeline',
  props: {
    data: {
      type: Array,
    },
  },
  data: () => ({
    page: 1,
  }),
  methods: {
    load() {
      this.page++;
      if (this.page > 1) {
        this.$emit('loadMore', this.page);
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * Math.floor(10000));
    },
    getSubjectType(activity) {
      return activity.subject_type.split('\\')[activity.subject_type.split('\\').length - 1];
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.time-line {
  padding-left: 3px;
  max-height: 600px;
  overflow: auto;
}

</style>
