const getFilenameExtension = (filename) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

export const isImage = (filename) => {
  if (filename) {
    switch (getFilenameExtension(filename)) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        return true;
    }
  }
  return false;
};

export const isVideo = (filename) => {
  if (filename) {
    switch (getFilenameExtension(filename)) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
        return true;
    }
  }
  return false;
};

export const checkFileSize = (file, maxMB, minMB = 0) => {
  const multiplicator = 1048576;
  return file.size <= maxMB * multiplicator && file.size >= minMB * multiplicator;
};

export function downloadFile(response, filename) {
  const blob = new Blob([response.data], {type: response.headers['content-type']});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export function downloadReport(response) {
  const header = response.headers['content-disposition'];
  let name = header?.slice(header.indexOf('=') + 1, header.length).toString() || 'Report';
  if (name.startsWith('"')) {
    name = name.replace('"', '');
  }
  downloadFile(response, name);
}
