<template>
  <div
      @mouseleave="isHovered = false"
      @mouseover="isHovered = true"
      v-loading="loading"
  >
    <el-card
        :shadow="shadow"
        body-style="padding: 10px 15px"
    >
      <div
          class="d-flex justify-content-between align-items-center"
          slot="header"
          style="height: 10px"
      >
        <span><b>{{ author.first_name ? author.first_name : '' }} {{
            author.last_name ? author.last_name : ''
                 }}</b></span>
        <transition name="el-fade">
          <span v-if="isHovered && !isEditModeEnabled">
            <el-button-group :size="size">
              <el-button
                  :size="size"
                  @click="enableEditMode"
                  v-if="ifHasPermission(['wo-note-edit'])"
              >
                <font-awesome-icon icon="edit" />
              </el-button>
              <el-button
                  :size="size"
                  @click="remove"
                  v-if="ifHasPermission(['wo-note-delete'])"
              >
                <font-awesome-icon icon="trash" />
              </el-button>
            </el-button-group>
          </span>
          <span v-else>{{ date }}</span>
        </transition>
      </div>
      <div v-if="isEditModeEnabled">
        <el-input
            :size="size"
            autosize
            type="textarea"
            v-model="editableContent"
        />
        <div class="mt-2 d-flex justify-content-between">
          <div>
            <el-checkbox
                class="pt-2"
                v-if="canBeOnlyForManagement"
                v-model="editableIsOnlyForManagement"
            >
              {{ $t('system.only_for_management') }}
            </el-checkbox>
          </div>
          <div>
            <el-button
                :size="size"
                @click="save"
                type="primary"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button
                :size="size"
                @click="disableEditMode"
            >
              {{ $t('system.cancel') }}
            </el-button>
          </div>
        </div>
      </div>
      <div v-else>
        <span style="word-wrap: break-word">
          {{ content }}
        </span>
        <div
            class="mt-2"
            style="font-size: 0.8em; color: #ff4949"
            v-if="isOnlyForManagement"
        >
          <b>{{ $t('system.only_for_management') }}</b>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'NoteComponent',
  props: {
    canBeOnlyForManagement: {
      type: Boolean,
      default: false,
    },
    author: {
      type: Object,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    isOnlyForManagement: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
    },
    shadow: {
      type: String,
      default: 'hover',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saveMethod: {
      type: Function,
    },
    size: {
      type: String,
      default: 'mini',
      validator: (prop) => [
        'mini',
        'small',
        'medium',
      ].includes(prop),
    },
  },
  data() {
    return {
      isEditModeEnabled: false,
      isHovered: false,
      editableContent: '',
      editableIsOnlyForManagement: false,
    };
  },
  methods: {
    async remove() {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.$emit('remove');
      }
    },
    save() {
      this.$emit('save', {
        content: this.editableContent,
        management_note: this.editableIsOnlyForManagement,
      });
      this.disableEditMode();
    },
    enableEditMode() {
      this.isEditModeEnabled = true;
      this.editableContent = this.content;
      this.editableIsOnlyForManagement = this.isOnlyForManagement;
    },
    disableEditMode() {
      this.isEditModeEnabled = false;
      this.editableContent = '';
      this.editableIsOnlyForManagement = this.isOnlyForManagement;
    },
  },
};
</script>

<style scoped>

</style>
