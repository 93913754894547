<template>
  <div>
    <h5 class="d-inline-block">
      {{ title }}
    </h5>
    <el-button
        @click="openCreateForm"
        class="d-inline-block float-right"
        size="mini"
        type="success"
        v-if="ifHasPermission(['wo-note-create']) && !isNoteCreateOpened"
    >
      <font-awesome-icon icon="plus" />&nbsp;
                                       {{ $t('system.add') }}
    </el-button>
    <transition name="fade">
      <div v-if="isNoteCreateOpened">
        <el-input
            :class="{'validate-error': !!backendErrors.content}"
            :disabled="saveLoading"
            :placeholder="`${$t('system.new_note')}...`"
            autofocus
            autosize
            ref="createNoteInput"
            size="mini"
            type="textarea"
            v-model="newNoteContent"
        />
        <div class="d-flex justify-content-between">
          <div>
            <el-checkbox
                class="pt-2"
                v-if="canBeOnlyForManagement"
                v-model="isOnlyForManagement"
            >
              {{ $t('system.only_for_management') }}
            </el-checkbox>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <el-button
                :loading="saveLoading"
                @click="saveNote"
                size="mini"
                type="success"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button
                :disabled="saveLoading"
                @click="disableCreateMode"
                size="mini"
            >
              {{ $t('system.cancel') }}
            </el-button>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <template v-if="notes.length">
        <NoteComponent
            :author="note.author"
            :can-be-only-for-management="canBeOnlyForManagement"
            :content="note.content"
            :date="note.created_at"
            :is-only-for-management="!!note.management_note"
            :key="note.id"
            :loading="loadingNoteId === note.id"
            @remove="removeNote(note.id)"
            @save="updateNote(...arguments, note.id)"
            class="mt-2"
            size="mini"
            v-for="note in notes"
        />
      </template>
      <span
          class="no-date-title mt-2"
          v-else
      >
        {{ $t('system.no_data') }}
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NoteComponent from './NoteComponent.vue';
import notification from '../notification/notify';

export default {
  name: 'NotesComponent',
  components: {NoteComponent},
  props: {
    canBeOnlyForManagement: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    },
    saveEndpoint: {
      type: Object,
    },
    updateEndpoint: {
      type: Object,
    },
    removeEndpoint: {
      type: Object,
    },
  },
  data() {
    return {
      isOnlyForManagement: false,
      saveLoading: false,
      loadingNoteId: null,
      isNoteCreateOpened: false,
      newNoteContent: '',
      backendErrors: {},
    };
  },
  methods: {
    disableCreateMode() {
      this.newNoteContent = '';
      this.isNoteCreateOpened = false;
    },
    async removeNote(id) {
      const url = this.removeEndpoint.url.replace(/:id/g, id);
      this.loadingNoteId = id;
      try {
        await axios[this.removeEndpoint.method](url);
        this.$emit('fetchData');
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            error.response.data.error,
            'error');
      } finally {
        this.loadingNoteId = null;
      }
    },
    openCreateForm() {
      this.isNoteCreateOpened = true;
      this.$nextTick(() => {
        this.$refs.createNoteInput.$refs.textarea.focus();
      });
    },
    closeCreateForm() {
      this.isNoteCreateOpened = false;
      this.newNoteContent = '';
    },
    async saveNote() {
      this.saveLoading = true;
      try {
        await axios[this.saveEndpoint.method](this.saveEndpoint.url, {
          content: this.newNoteContent,
          //TODO change parameter name if necessary
          management_note: this.isOnlyForManagement,
        });
        this.$emit('fetchData');
        this.closeCreateForm();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t(error.response.data.content),
            'warning');
        this.backendErrors = error.response.data;
      } finally {
        this.saveLoading = false;
      }
    },
    async updateNote({content, management_note}, id) {
      this.loadingNoteId = id;
      const url = this.updateEndpoint.url.replace(/:id/g, id);
      try {
        await axios[this.updateEndpoint.method](url, {content, management_note});
        this.$emit('fetchData');
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            error.response.data.error,
            'error');
      } finally {
        this.loadingNoteId = null;
      }
    },
  },
};
</script>

<style scoped>

</style>
