<template>
  <DataTable
      :headers="headers"
      :loading="loading || localLoading"
      :model="data"
      :pagination="false"
  >
    <template v-slot:item.periodName="{row}">
      <div class="d-flex mx-2">
        <span>{{ $t(`system.${row.period_name}`) }}</span>
      </div>
    </template>
    <template v-slot:item.inDays="{row}">
      <div class="d-flex mx-2">
        <span>{{ getDaysOfWeekOrMonth(row) }}</span>
      </div>
    </template>
    <template v-slot:item.options="{row}">
      <div class="d-flex justify-content-center align-content-center cell_remove">
        <i
            @click.stop="removeItem(row)"
            slot="reference"
        >
          <font-awesome-icon
              icon="times"
          />
        </i>
      </div>
    </template>
  </DataTable>
</template>

<script>
import axios from 'axios';
import DataTable from '@/components/Table/DataTable';
import notification from '@/notification/notify';
import swal from 'sweetalert2';

const daysOfWeek = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
};

export default {
  name: 'ScheduleOrders',
  components: {DataTable},
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    headers: [
      {
        key: 'options',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'start_date',
        label: 'system.start_date',
        sort: false,
      },
      {
        key: 'next_run',
        label: 'system.next_run',
        sort: false,
      },
      {
        key: 'end_date',
        label: 'system.end_date',
        sort: false,
      },
      {
        key: 'periodName',
        label: 'system.type',
        sort: false,
      },
      {
        key: 'period_number',
        label: 'system.repeat_every',
        sort: false,
        justify: 'center',
      },
      {
        key: 'inDays',
        label: 'system.in_days',
        sort: false,
      },
    ],
    localLoading: false,
  }),
  methods: {
    async removeItem(row) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        try {
          this.localLoading = true;
          await axios.delete(`workorders/schedule_order/${row.id}`);
          this.$emit('fetchData');
        } catch (e) {
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_remove'),
              'error');
        } finally {
          this.localLoading = false;
        }
      }
    },
    getDaysOfWeekOrMonth(row) {
      if (row.days_of_week?.length) {
        return row.days_of_week.map(item => this.$t(`system.${daysOfWeek[item]}`)).join(', ');
      } else if (row.days_of_month?.length) {
        return row.days_of_month.join(', ');
      }
      return null;
    },
  },
};
</script>

<style scoped>

</style>
