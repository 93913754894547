<template>
  <div>
    <el-tabs
        stretch
        type="border-card"
        v-model="activeTab"
    >
      <el-tab-pane
          :key="tab.name"
          :label="$t(tab.label)"
          :name="tab.name"
          v-for="tab in tabs"
      >
        <TabContent
            :loading="loading"
            :tab="tab.name"
            :workOrderId="workOrderId"
            v-loading="loading"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import TabContent from '@/components/WorkOrderComponents/Payments/TabContent';
import axios from 'axios';
import notification from '@/notification/notify';

export default {
  name: 'WOPaymentsIndex',
  components: {TabContent},
  props: {
    workOrderId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    tabs: [
      {
        name: 'ProductsAndServices',
        label: 'system.products_and_services',
      },
      {
        name: 'DriveAndLaborTimes',
        label: 'system.drive_and_labor_times',
      },
      {
        name: 'Expenses',
        label: 'system.expenses',
      },
    ],
    activeTab: 'ProductsAndServices',
    loading: false,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('workOrderPayments', ['addServiceItem', 'addExpense', 'addDriveAndLaborTime']),
    async fetchData() {
      try {
        this.loading = true;
        const {data} = await axios.get(`workorders/expense/${this.workOrderId}`);

        data.data.product_service_positions.forEach(item => {
          this.addServiceItem({
            workOrderId: this.workOrderId,
            service: item,
          });
        });
        data.data.drive_labor_time_positions.forEach(item => {

          this.addDriveAndLaborTime({
            workOrderId: this.workOrderId,
            item: {
              ...item,
              drive_from_hours: item.drive_from_hours.slice(0, -3),
              drive_to_hours: item.drive_to_hours.slice(0, -3),
              labor_from_hours: item.labor_from_hours.slice(0, -3),
              labor_to_hours: item.labor_to_hours.slice(0, -3),
              drive_billable: !!item.drive_billable,
              labor_billable: !!item.labor_billable,
            },
          });
        });
        data.data.expense_positions.forEach(item => {
          this.addExpense({
            workOrderId: this.workOrderId,
            item: {...item, files: item.receipts},
          });
        });
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_payments'),
            'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
