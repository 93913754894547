<template>
  <div>
    <ContentWrapper class="work-order">
      <div class="d-flex justify-content-between">
        <h4>{{ $t('system.job') }} #{{ id }}</h4>
        <div>
          <el-button
              :loading="informVendorLoading"
              @click="sendEmailToVendor"
              size="mini"
              type="info"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            {{ $t('system.inform_vendor') }}
          </el-button>
          <el-button
              @click="$router.push({name: 'WorkOrderEdit', params: {id}})"
              size="mini"
              type="primary"
              v-if="ifHasPermission(['wo-order-edit']) && !edit_disabled"
          >
            {{ $t('system.edit') }}
          </el-button>
        </div>
      </div>
      <el-divider />
      <div class="row">
        <div class="col-5">
          <h5 class="mb-4">{{ $t('system.details') }}</h5>
          <el-table
              :data="getDetails()"
              :show-header="false"
          >
            <el-table-column prop="label">
              <template slot-scope="scope">
                <span :style="{color: scope.row.color}">
                  <font-awesome-icon :icon="scope.row.icon" />&nbsp;
                  <b>{{ $t(scope.row.label) }}</b>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="value">
              <template slot-scope="scope">
                <template v-if="scope.row.value && scope.row.value.length">
                  <span :style="{color: scope.row.color}">
                    <router-link
                        :to="scope.row.url"
                        v-if="scope.row.url"
                    >
                      <span class="link-type">
                        {{ scope.row.value }}
                      </span>
                    </router-link>
                    <template v-else-if="scope.row.key === 'start_at'">
                        {{ scope.row.value.split(' ')[0] }}
                    </template>
                    <template v-else-if="scope.row.key === 'done_at'">
                        {{ scope.row.value.split(' ')[0] }}
                    </template>
                    <template v-else-if="scope.row.key === 'managers'">
                      <div
                          :key="idx"
                          v-for="(manager, idx) in scope.row.value"
                      >
                        <font-awesome-icon icon="user-tie" />&nbsp;
                        {{ manager }}
                      </div>
                    </template>
                    <template v-else-if="scope.row.key === 'nte'">
                        USD {{ scope.row.value }}
                    </template>
                    <template v-else-if="scope.row.key === 'agents'">
                      <div
                          :key="idx"
                          v-for="(agent, idx) in scope.row.value"
                      >
                        <font-awesome-icon icon="user-tag" />&nbsp;
                        {{ agent }}
                      </div>
                    </template>
                    <template v-else-if="scope.row.key === 'status'">
                      <div>
                        {{ $t(`system.${scope.row.value}`) }}
                      </div>
                    </template>
                    <template v-else-if="scope.row.key === 'technicians'">
                    <div
                        :key="idx"
                        v-for="(tech, idx) in scope.row.value"
                    >
                      <font-awesome-icon icon="user-cog" />&nbsp;
                      {{ tech }}
                    </div>
                  </template>
                  <template
                      v-else-if="scope.row.key === 'equipment'
                                      || scope.row.key === 'products'
                                      || scope.row.key === 'tools'
                                      || scope.row.key === 'vehicles'"
                  >
                    <span
                        :key="idx"
                        v-for="(item, idx) in scope.row.value"
                    >
                      {{ item }}<template v-if="idx < scope.row.value.length - 1">, </template>
                    </span>
                  </template>
                    <template v-else>
                      {{ scope.row.value }}
                    </template>
                  </span>
                </template>
                <span
                    class="no-data"
                    v-else
                >
                  {{ $t('system.no_data') }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-7">
          <div v-if="!ifHasPermission(['wo-contractor']) || !!user.admin">
            <h5 class="mb-4">{{ $t('system.locations') }}</h5>
            <LocalizationsIndex
                :orderId="id"
            />
            <!--            </el-dialog>-->
          </div>
          <div
              class="mt-5"
              v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
          >
            <div class="d-flex justify-content-between align-center">
              <h5 class="mb-4">{{ $t('system.recurring_orders') }}</h5>
              <el-button
                  @click="openRecurringCalendar"
                  size="mini"
                  type="success"
              >
                <font-awesome-icon icon="plus" />
              </el-button>
            </div>
            <ScheduleOrders
                :data="scheduleOrders"
                :loading="scheduleOrdersListLoading"
                @fetchData="fetchScheduleOrdersList"
            />
          </div>
          <div class="row mt-5">
            <div class="col-12 mb-4">
              <h5>{{ $t('system.attachments') }}</h5>
            </div>
            <div class="col-6">
              <h6>{{ $t('system.pictures') }}</h6>
              <FilesList
                  :downloadProgress="pictureDownloadProgress"
                  :files="pictures"
                  @download="downloadPicture"
                  @itemClicked="openPreview"
                  download
                  imageType
              />
            </div>
            <div class="col-6">
              <h6>{{ $t('system.documents') }}</h6>
              <FilesList
                  :downloadProgress="documentDownloadProgress"
                  :files="documents"
                  @download="downloadDocument"
                  download
              />
            </div>
            <div
                class="col-12"
                v-loading="fetchSignatureLoading"
                v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
            >
              <h5>{{ $t('system.signature') }}</h5>
              <img
                  :src="signature"
                  class="mx-auto d-block"
                  height="400px"
                  v-if="signature"
              />
              <span
                  class="no-date-title"
                  v-else
              >{{ $t('system.no_signature') }}</span>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
    <div
        class="row mt-6"
        v-if="ifHasPermission(['wo-order-expenses']) && !ifHasPermission(['wo-contractor']) || !!user.admin"
    >
      <div class="col-12">
        <WOPaymentsIndex
            :workOrderId="id"
        />
      </div>
    </div>
    <div
        class="row mt-6"
        v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
    >
      <div
          class="col-4"
          v-if="ifHasPermission(['wo-task-read'])"
      >
        <ContentWrapper>
          <WorkOrderIssues
              :title="$t('system.issues')"
              :workOrderId="id"
              style="min-height: 70px"
          />
        </ContentWrapper>
      </div>
      <div
          class="col-4"
          v-if="ifHasPermission(['wo-note-read'])"
      >
        <ContentWrapper>
          <NotesComponent
              :notes="notes"
              :removeEndpoint="{
              method: 'delete',
              url: 'workorders/note/:id'
            }"
              :saveEndpoint="{
              method: 'post',
              url: `workorders/order/${id}/note`
            }"
              :title="$t('system.notes')"
              :updateEndpoint="{
              method: 'post',
              url: 'workorders/note/:id'
            }"
              @fetchData="fetchNotes(true)"
              can-be-only-for-management
              style="min-height: 70px"
          />
        </ContentWrapper>
      </div>
      <div
          class="col-4"
          v-if="!ifHasPermission(['wo-contractor']) || !!user.admin"
      >
        <ContentWrapper>
          <h5 class="mb-5">{{ $t('system.activities') }}</h5>
          <WorkOrderTimeline
              :data="activities.data"
              @loadMore="fetchActivities(true)"
          />
        </ContentWrapper>
      </div>
    </div>
    <LightBox
        :index="index"
        :items="galleryImages"
        :slideshow="false"
        @close="index = null"
        srcThumb="src"
        thumbsPosition="bottom"
        useZoomBar
    />
    <el-dialog
        :title="$t('system.recurring_calendar')"
        :visible.sync="isRecurringCalendarVisible"
        class="recurring-dialog"
        destroy-on-close
        width="500px"
    >
      <RecurringCalendar
          :saveLoading="createScheduleOrderLoading"
          @close="closeRecurringCalendar"
          @save="setRecurringCalendarData"
      />
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import NotesComponent from '../../components/NotesComponent.vue';
import {HHmmssToNormalTime} from '@/helpers/time';
import FilesList from '../../components/FilesList/FilesList.vue';
import {downloadFile} from '@/helpers/files';
import notification from '../../notification/notify';
import WorkOrderTimeline from '../../components/Timeline/WorkOrderTimeline.vue';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import ScheduleOrders from '@/components/WorkOrderComponents/ScheduleOrders';
import WOPaymentsIndex from '@/components/WorkOrderComponents/Payments/Index';
import {mapState} from 'vuex';

export default {
  name: 'WorkOrder',
  components: {
    WOPaymentsIndex,
    ScheduleOrders,
    RecurringCalendar: () => import('@/components/RecurringEventsCalendar/Index.vue'),
    LocalizationsIndex: () => import('@/components/WorkOrderComponents/Localizations/Index'),
    WorkOrderIssues: () => import('@/components/WorkOrderComponents/Issues/Index'),
    WorkOrderTimeline,
    FilesList,
    NotesComponent,
    ContentWrapper,
    LightBox: () => import('vue-cool-lightbox'),
  },
  props: ['id'],
  data() {
    return {
      informVendorLoading: false,
      isRecurringCalendarVisible: false,
      isLocalizationFormVisible: false,
      edit_disabled: true,
      locations: [],
      issueTableHeaders: [
        {
          key: 'isChecked',
          label: '',
          sort: false,
        },
        {
          key: 'content',
          label: 'system.content',
        },
        {
          key: 'options',
          label: 'system.options',
        },
      ],
      index: null,
      details: [
        {
          key: 'po',
          label: 'system.po',
          icon: 'clipboard-list',
          value: '',
        },
        {
          key: 'account',
          label: 'system.customer',
          icon: 'user-friends',
          value: '',
        },
        {
          key: 'contact',
          label: 'system.contact',
          icon: 'phone',
          value: '',
        },
        {
          key: 'managers',
          label: 'system.managers',
          icon: 'user-tie',
          value: '',
        },
        {
          key: 'agents',
          label: 'system.agents',
          icon: 'user-tag',
          value: '',
        },
        {
          key: 'status',
          label: 'system.status',
          icon: 'battery-three-quarters',
          value: '',
        },
        {
          key: 'start_at',
          label: 'system.start_at',
          icon: 'calendar',
          value: '',
        },
        {
          key: 'done_at',
          label: 'system.done_at',
          icon: 'calendar',
          value: '',
        },
        {
          key: 'arrival_range',
          label: 'system.arrival_range',
          icon: 'car',
          value: '',
        },
        {
          key: 'estimation',
          label: 'system.estimation',
          icon: 'stopwatch',
          value: '',
        },
        {
          key: 'nte',
          label: 'system.not_to_exceed',
          icon: 'dollar-sign',
          value: '',
          color: 'red',
        },
        {
          key: 'priority',
          label: 'system.priority',
          icon: 'exclamation-triangle',
          value: '',
        },
        {
          label: 'system.technicians',
          key: 'technicians',
          icon: 'users-cog',
          value: [],
        },
        {
          label: 'system.vehicle',
          key: 'vehicles',
          icon: 'truck',
          value: [],
        },
        {
          label: 'system.equipment',
          key: 'equipment',
          icon: 'toolbox',
          value: [],
        },
        {
          label: 'system.tools',
          key: 'tools',
          icon: 'wrench',
          value: [],
        },
        {
          label: 'system.products',
          key: 'products',
          icon: 'cubes',
          value: [],
        },
        {
          key: 'description',
          label: 'system.work_description',
          icon: 'comment-dots',
          value: '',
        },
        {
          key: 'note_for_technician',
          label: 'system.note_for_techs',
          icon: 'comment-dots',
          value: '',
        },
        {
          key: 'completion_note',
          label: 'system.completion_note',
          icon: 'comment-dots',
          value: '',
          color: '#273863',
        },
        {
          key: 'note_to_customer',
          label: 'system.note_to_customer',
          icon: 'comment-dots',
          value: '',
          color: '#2e6323',
        },
      ],
      pictures: [],
      documents: [],
      notes: [],
      issues: [],
      activities: {
        data: [],
        per_page: 10,
        total: 0,
      },
      pictureDownloadProgress: {
        id: null,
        progress: 0,
      },
      documentDownloadProgress: {
        id: null,
        progress: 0,
      },
      galleryImages: [],
      scheduleOrders: [],
      scheduleOrdersListLoading: false,
      createScheduleOrderLoading: false,
      signature: null,
      fetchSignatureLoading: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  activated() {
    this.fetchWorkOrder();
    this.fetchDocuments();
    this.fetchImages();
    if (!this.ifHasPermission(['wo-contractor']) || !!this.user.admin) {
      this.fetchScheduleOrdersList();
      this.fetchIssues();
      this.fetchNotes();
      this.fetchActivities();
      this.fetchSignature();
    }
  },
  methods: {
    getDetails() {
      const listForContractor = [
        'po',
        'account',
        'contact',
        'managers',
        'status',
        'start_at',
        'done_at',
        'arrival_range',
        'nte',
        'priority',
        'description',
        'note_for_technician',
      ];
      if (!this.ifHasPermission(['wo-contractor']) || !!this.user.admin) {
        return this.details;
      }
      return this.details
          .filter((element) => listForContractor
              .find((subElement) => element.key === subElement),
          );
    },

    async sendEmailToVendor() {
      try {
        this.informVendorLoading = true;
        await axios.get(`/workorders/vendor_info/${this.id}`);
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.vendor_was_informed'),
            'success');
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.vendor_was_not_informed'),
            'error');
      } finally {
        this.informVendorLoading = false;
      }
    },
    async fetchSignature() {
      try {
        this.fetchSignatureLoading = true;
        const {data} = await axios.get(`workorders/signature/${this.id}`, {
          responseType: 'blob',
          params: {type: 'mime_type'},
        });
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          this.signature = reader.result;
        };
      } catch (e) {
        if (e.response.status !== 404) {
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_load_signature'),
              'error');
        }
      } finally {
        this.fetchSignatureLoading = false;
      }
    },
    async disableRecurringOrders() {
      try {
        await axios.delete(`workorders/schedule_order/${this.id}`);
        await this.fetchScheduleOrdersList();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_disable_recurring_orders'),
            'error');
      }
    },
    async fetchScheduleOrdersList() {
      try {
        this.scheduleOrdersListLoading = true;
        const {data} = await axios.get(`workorders/schedule_order/${this.id}`);
        this.scheduleOrders = data.map(item => ({
          ...item,
          start_date: item.start_date.slice(0, -9),
          next_run: item.next_run.slice(0, -9),
          end_date: item.end_date.slice(0, -9),
        }));
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_fetch_schedule_orders'),
            'error');
      } finally {
        this.scheduleOrdersListLoading = false;
      }
    },
    async setRecurringCalendarData(data) {
      this.createScheduleOrderLoading = true;
      const preparedData = {
        start_date: data.startDate,
        period_number: data.repeatValue,
        period_name: data.repeatType,
        end_date: data.finishDate,
      };
      if (data.repeatType === 'weekly') {
        preparedData.days_of_week = data.days;
      } else if (data.repeatType === 'monthly') {
        preparedData.days_of_month = data.days;
      } else if (data.repeatType === 'yearly') {
        preparedData.yearly_month = data.month;
        preparedData.yearly_day = data.day;
      }

      try {
        await axios.post(`workorders/schedule_order/${this.id}`, preparedData);
        this.closeRecurringCalendar();
        await this.fetchScheduleOrdersList();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_create_recurring_orders'),
            'error');
      } finally {
        this.createScheduleOrderLoading = false;
      }
    },
    closeRecurringCalendar() {
      this.isRecurringCalendarVisible = false;
    },
    openRecurringCalendar() {
      this.isRecurringCalendarVisible = true;
    },
    getDetailsValueByKey(key) {
      const candidate = this.details.find(item => item.key === key);
      if (candidate) {
        return candidate.value;
      }
      return null;
    },
    async fetchImageForPreview(id) {
      const {data} = await axios.get(`/workorders/attachment/${id}`, {
        responseType: 'blob',
        params: {type: 'mime_type'},
      });
      return data;
    },
    async openPreview(file) {
      const index = this.pictures.findIndex(item => item.id === file.id);
      if (index !== -1) {
        this.galleryImages = this.pictures.map((item, idx) => {
          const image = this.galleryImages.find(galleryImage => galleryImage.id === item.id);
          if (!image) {
            return {
              id: item.id,
              title: '',
              src: '',
              isDownloaded: false,
            };
          }
          return image;
        });
        if (!this.galleryImages[index].isDownloaded) {
          this.index = 9999;
          const firstImage = await this.fetchImageForPreview(file.id);
          const reader = new FileReader();
          reader.readAsDataURL(firstImage);
          reader.onload = () => {
            this.galleryImages[index].title = '';
            this.galleryImages[index].src = reader.result;
            this.galleryImages[index].isDownloaded = true;

            this.index = index;

            this.pictures.map(async (item, idx) => {
              if (idx !== index || !this.galleryImages[idx].isDownloaded) {
                const image = await this.fetchImageForPreview(item.id);
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = () => {
                  this.galleryImages[idx].title = '';
                  this.galleryImages[idx].src = reader.result;
                  this.galleryImages[idx].isDownloaded = true;
                };
              }
            });
          };
        } else {
          this.index = index;
        }
      }
    },
    async fetchImages() {
      try {
        const {data} = await axios.get(`/workorders/order/${this.id}/images`);
        this.pictures = data.data;
      } catch (error) {
        console.log('fetchFilesError', error.message);
      }
    },
    async fetchDocuments() {
      try {
        const {data} = await axios.get(`/workorders/order/${this.id}/documents`);
        this.documents = data;
      } catch (error) {
        console.log('fetchDocumentsError', error.message);
      }
    },
    async downloadPicture(item) {
      this.pictureDownloadProgress.id = item.id;
      const file = await axios.get(`/workorders/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.pictureDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.pictureDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },
    async downloadDocument(item) {
      this.documentDownloadProgress.id = item.id;
      const file = await axios.get(`/workorders/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.documentDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.documentDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },
    async fetchWorkOrder() {
      try {
        const {data} = await axios.get(`workorders/order/${this.id}`);

        const isSubcontracted = !!data.data.is_subcontracted;
        if (isSubcontracted) {
          const techs = this.details.find(item => item.key === 'technicians');
          if (techs) {
            techs.key = 'subcontractor';
            techs.label = 'system.subcontractor';
          }

          const note = this.details.find(item => item.key === 'note_for_technician');
          note.label = 'system.note_for_subcontractor';

          const managerIdx = this.details.findIndex(item => item.key === 'managers');
          if (managerIdx >= 0) {
            this.details.splice(managerIdx, 1);
          }
        }
        this.locations = data.data.locations;
        this.edit_disabled = data.data.edit_disabled;

        this.details = this.details.map(item => {
          if (data.data[item.key]) {
            if (item.key === 'estimation') {
              item.value = data.data[item.key] ? HHmmssToNormalTime(data.data[item.key]) : null;
            } else if (item.key === 'po') {
              item.value = data.data[item.key].toString();
            } else {
              item.value = data.data[item.key];
            }
          } else if (item.key === 'arrival_range') {
            if (data?.data?.arrival_start && data?.data?.arrival_end) {
              const startDate = moment(data.data.arrival_start).format('YYYY-MM-DD hh:mm A');
              const endDate = moment(data.data.arrival_end).format('hh:mm A');
              item.value = `${startDate} - ${endDate}`;
            }
          }
          return item;
        });
      } catch (error) {
        console.log('error', error);
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_work_order'),
            'error');
      }
    },
    async fetchNotes(withActivities = false) {
      try {
        const {data} = await axios.get(`workorders/order/${this.id}/notes`);
        this.notes = data;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_notes'),
            'error');
      }
      if (withActivities) {
        this.fetchActivities();
      }
    },
    async fetchIssues(withActivities = false) {
      try {
        const {data} = await axios.get(`workorders/order/${this.id}/tasks`);
        this.issues = data;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_issues'),
            'error');
      }
      if (withActivities) {
        this.fetchActivities();
      }
    },
    async fetchActivities(triggeredByScroll = false) {
      if (triggeredByScroll && this.activities.total > this.activities.per_page) {
        this.activities.per_page += 10;
      }
      try {
        const {data} = await axios.get(`workorders/activities/${this.id}`, {
          params: {per_page: this.activities.per_page},
        });
        this.activities.data = data.data;
        this.activities.total = data.total;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_activities'),
            'error');
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.work-order {
  .el-table td {
    vertical-align: baseline !important;
  }
}
</style>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.no-data {
  opacity: .3;
}
</style>
