<template>
  <ul class="fieldUpload--list">
    <template v-if="files.length">
      <li
          :key="idx"
          @click="$emit('itemClicked', item)"
          class="el-upload-list__item"
          v-for="(item, idx) in files"
      >
        <a
            :style="`margin-right: ${remove ? '25px' : '0'}`"
            class="el-upload-list__item-name"
        >
          <img
              :src="item.thumbnailUrl"
              class="el-icon-document"
              style="width: 15px; max-height: 15px"
              v-if="imageType && item.thumbnailUrl"
          />
          <i
              class="el-icon-document"
              v-else
          />
          <span>{{ item.filename }}</span>
          <i
              @click="$emit('download', item)"
              class="float-right"
              slot="prefix"
              v-if="download"
          >
            <font-awesome-icon icon="arrow-circle-down" />
          </i>
        </a>

        <transition name="el-fade-in">
          <el-progress
              :percentage="downloadProgress.progress"
              :show-text="false"
              :stroke-width="3"
              status="success"
              v-if="downloadProgress && item.id === downloadProgress.id"
          />
        </transition>
        <i
            @click="$emit('remove', item)"
            class="el-icon-close"
            v-if="remove"
        />
      </li>
    </template>
    <span
        class="no-date-title"
        v-else
    >{{ $t('system.no_data') }}</span>
  </ul>
</template>

<script>
export default {
  name: 'FilesList',
  props: {
    files: {
      type: Array,
      required: true,
    },
    imageType: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    downloadProgress: {
      type: Object,
    },
  },
};
</script>
