<template>
  <div>
    <component
        :is="tab"
        :workOrderId="workOrderId"
    />
    <div class="d-flex justify-content-end">
      <div class="total-component">
        <TotalInfo :workOrderId="workOrderId" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'TabContent',
  components: {
    TotalInfo: () => import('@/components/WorkOrderComponents/Payments/TotalInfo'),
    ProductsAndServices: () => import('./ProductsAndServices/Index.vue'),
    DriveAndLaborTimes: () => import('./DriveAndLaborTimes/Index.vue'),
    Expenses: () => import('./Expenses/Index.vue'),
  },
  props: {
    tab: {
      type: String,
      required: true,
    },
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters('workOrderPayments', ['paymentNote']),
    note: {
      set(text) {
        this.setPaymentNote({
          workOrderId: this.workOrderId,
          text,
        });
      },
      get() {
        return this.paymentNote(this.workOrderId);
      },
    },
  },
  methods: {
    ...mapActions('workOrderPayments', ['setPaymentNote']),
  },
};
</script>

<style
    lang="scss"
    scoped
>
.total-component {
  width: 600px;
  margin-top: 8px;
}

.note {
  margin-right: 20px;

  &__title {
    font-size: 0.9em;
  }

  &__input {
  }
}
</style>
